import React from 'react'
import { Maybe } from '../../generated/graphql-types'
import { getIconFromName } from './getIconFromName'
import * as styles from './IngredientIcon.module.css'

interface Props {
  ingredient: Maybe<string>
}
export const IngredientIcon = ({ ingredient }: Props) => {
  const icon = getIconFromName(ingredient)
  return <span className={styles.icon} style={{ backgroundImage: `url(/icons/${icon}.svg)` }} />
}
