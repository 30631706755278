import React from 'react'
import * as styles from './RecipeDirections.module.css'
import { SitePageContextRecipe } from '../../generated/graphql-types'

interface Props {
  directions: SitePageContextRecipe['recipeInstructions']
}

export const RecipeDirections = ({ directions }: Props) => {
  if (!directions || !directions.length) {
    return null
  }

  return (
    <div className={styles.directions}>
      <h2>Directions</h2>

      <ol>{directions.map((direction, key) => direction && <li key={key}>{direction.text}</li>)}</ol>
    </div>
  )
}
