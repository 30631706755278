import React from 'react'
import * as styles from './RecipeIngredients.module.css'
import { SitePageContextRecipe } from '../../generated/graphql-types'
import { IngredientIcon } from '../IngredientIcon/IngredientIcon'

interface Props {
  ingredients: SitePageContextRecipe['recipeIngredient']
}

export const RecipeIngredients = ({ ingredients }: Props) => {
  if (!ingredients || !ingredients.length) {
    return null
  }

  return (
    <div className={styles.ingredients}>
      <h2>Ingredients</h2>

      <ul className={styles.ingredientList}>
        {ingredients.map((ingredient, key) => (
          <li key={key} className={styles.ingredient}>
            <IngredientIcon ingredient={ingredient} />
            <span className={styles.text}>{ingredient}</span>
          </li>
        ))}
      </ul>
    </div>
  )
}
