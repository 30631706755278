import React from 'react'
import * as styles from './Container.module.css'

interface Props {
  children: React.ReactNode
}

export const Container = ({ children }: Props) => {
  return <div className={styles.container}>{children}</div>
}
