import React from 'react'
import Img, { FluidObject } from 'gatsby-image'
import { ImageSharp, Maybe } from '../../generated/graphql-types'
import * as styles from './RecipeImage.module.css'

interface Props {
  image: Maybe<{
    childImageSharp: ImageSharp
  }>
}

export const RecipeImage = ({ image }: Props) => {
  if (!image) {
    return null
  }

  return <Img fluid={image.childImageSharp.fluid as FluidObject} alt="" className={styles.image} />
}
