import React from 'react'
import * as styles from './RecipeHeader.module.css'
import { SitePageContextRecipe } from '../../generated/graphql-types'

interface Props {
  name: SitePageContextRecipe['name']
  category: SitePageContextRecipe['recipeCategory']
}

export const RecipeHeader = ({ name, category }: Props) => {
  if (!name) {
    return null
  }

  return (
    <div className={styles.header}>
      {category && <p className={styles.category}>{category}</p>}
      <h1>{name}</h1>
    </div>
  )
}
