import React from 'react'
import { parse } from 'iso8601-duration'
import { formatDuration } from 'date-fns'
import * as styles from './RecipeOverview.module.css'
import { Maybe } from '../../generated/graphql-types'

interface Props {
  serves?: Maybe<number>
  duration?: Maybe<string>
  prepTime?: Maybe<string>
}

export const RecipeOverview = ({ serves, duration, prepTime }: Props) => {
  return (
    <dl className={styles.overview}>
      {serves && (
        <>
          <dt className={styles.title}>Serves</dt>
          <dd className={styles.description}>{serves}</dd>
        </>
      )}
      {prepTime && (
        <>
          <dt className={styles.title}>Prep Time</dt>
          <dd className={styles.description}>{formatDuration(parse(prepTime))}</dd>
        </>
      )}
      {duration && (
        <>
          <dt className={styles.title}>Ready in</dt>
          <dd className={styles.description}>{formatDuration(parse(duration))}</dd>
        </>
      )}
    </dl>
  )
}
