import React from 'react'
import { graphql, Link, PageProps } from 'gatsby'
import { SitePage } from '../generated/graphql-types'

interface Data {
  allSitePage: {
    pages: SitePage[]
  }
}
type Props = PageProps<Data>

export default function Home({ data }: Props) {
  return (
    <div>
      <h1>Recipes</h1>
      <ul>
        {data.allSitePage.pages.map((page) => (
          <li key={page?.pageContext?.slug}>
            <Link to={page.path}>{page?.pageContext?.recipe?.name}</Link>
          </li>
        ))}
      </ul>
    </div>
  )
}

export const pageQuery = graphql`
  query {
    allSitePage(filter: { path: { glob: "/recipe/*" } }) {
      pages: nodes {
        path
        pageContext
      }
    }
  }
`
