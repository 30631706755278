import React from 'react'
import { graphql, PageProps } from 'gatsby'
import { ImageSharp, Maybe, SitePageContext } from '../generated/graphql-types'
import { RecipeHeader } from '../components/RecipeHeader/RecipeHeader'
import { Container } from '../components/Container/Container'
import { RecipeIngredients } from '../components/RecipeIngredients/RecipeIngredients'
import { RecipeDirections } from '../components/RecipeDirections/RecipeDirections'
import { RecipeDetails } from '../components/RecipeDetails/RecipeDetails'
import { RecipeImage } from '../components/RecipeImage/RecipeImage'
import { Helmet } from 'react-helmet'
import { RecipeOverview } from '../components/RecipeOverview/RecipeOverview'

interface Data {
  image: Maybe<{
    childImageSharp: ImageSharp
  }>
}

type RecipePageContext = { [P in keyof SitePageContext]-?: NonNullable<SitePageContext[P]> }

type Props = PageProps<Data, RecipePageContext>

export default function RecipeTemplate({ data: { image }, pageContext: { recipe } }: Props) {
  return (
    <section className="sheet padding-10mm">
      <Helmet title={recipe.name || undefined} />
      <RecipeHeader name={recipe.name} category={recipe.recipeCategory} />

      <Container>
        <RecipeIngredients ingredients={recipe.recipeIngredient} />
        <RecipeDetails>
          <RecipeImage image={image} />
          <RecipeOverview serves={recipe.recipeYield} prepTime={recipe.prepTime} duration={recipe.totalTime} />
          <RecipeDirections directions={recipe.recipeInstructions} />
        </RecipeDetails>
      </Container>
    </section>
  )
}

export const query = graphql`
  query RecipeQuery($slug: String) {
    image: file(name: { eq: $slug }, extension: { ne: "yaml" }) {
      childImageSharp {
        fluid(maxWidth: 350, maxHeight: 200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
